import usePartySocket from "partysocket/react";
import { createContext, useContext, useEffect, useState } from "react";

type Position = {
  lat: number;
  long: number;
};

type Cursor = Position & {
  lastUpdate: number;
  color: string;
};

export type OtherCursorsMap = {
  [id: string]: Cursor;
};

interface CursorsContextType {
  others: OtherCursorsMap;
  sendPosition: (lat?: number, long?: number) => void;
}

export const CursorsContext = createContext<CursorsContextType>({
  others: {},
  sendPosition: () => {},
});

export function useCursors() {
  return useContext(CursorsContext);
}

export default function CursorsContextProvider(props: {
  token: string | null;
  host: string;
  children: React.ReactNode;
}) {
  const [others, setOthers] = useState<OtherCursorsMap>({});

  const socket = usePartySocket({
    host: props.host,
    room: "cursors",
    party: "cursors",
    query: {
      token: props.token,
    },
  });

  useEffect(() => {
    if (!socket) {
      return;
    }
    const onMessage = (evt: WebSocketEventMap["message"]) => {
      const msg = JSON.parse(evt.data as string);
      switch (msg.type) {
        case "sync":
          const newOthers = { ...msg.cursors };
          setOthers(newOthers);
          break;
        case "update":
          setOthers((others) => ({ ...others, [msg.id]: msg }));
          break;
        case "remove":
          setOthers((others) => {
            const newOthers = { ...others };
            delete newOthers[msg.id];
            return newOthers;
          });
          break;
      }
    };
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket]);

  const sendPosition = (lat?: number, long?: number) => {
    if (!socket) return;
    const position = {
      lat,
      long,
    } as Position;
    socket.send(JSON.stringify(position));
  };

  return (
    <CursorsContext.Provider
      value={{
        others: others,
        sendPosition: sendPosition,
      }}
    >
      {props.children}
    </CursorsContext.Provider>
  );
}
