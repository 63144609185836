import usePartySocket from "partysocket/react";
import { createContext, useContext, useEffect, useState } from "react";

export type BroadcastMessage = {
  type: "new";
} & Message;

export type SyncMessage = {
  type: "sync";
  messages: Message[];
};

export type Message = {
  id: string;
  from: string;
  text: string;
  sentAt: number;
  color: string;
};

type ChatMessage = BroadcastMessage | SyncMessage;

interface ChatContextType {
  messages: Message[];
  sendMessage: (text: string) => void;
}

export const ChatContext = createContext<ChatContextType>({
  messages: [],
  sendMessage: () => {},
});

export function useMessages() {
  return useContext(ChatContext);
}

export default function ChatContextProvider(props: {
  token: string | null;
  host: string;
  children: React.ReactNode;
}) {
  const [messages, setMessages] = useState<Message[]>([]);

  const socket = usePartySocket({
    host: props.host,
    room: "chat",
    party: "chat",
    query: {
      token: props.token,
    },
  });

  useEffect(() => {
    if (!socket) {
      return;
    }
    const onMessage = (evt: WebSocketEventMap["message"]) => {
      const msg = JSON.parse(evt.data as string) as ChatMessage;
      switch (msg.type) {
        case "sync":
          setMessages(msg.messages);
          break;
        case "new":
          setMessages((prev) => [...prev, msg]);
          break;
      }
    };
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket]);

  const sendMessage = (text: string) => {
    if (!socket) return;
    const message = {
      text,
    } as Message;
    socket.send(JSON.stringify(message));
  };

  return (
    <ChatContext.Provider
      value={{
        messages: messages,
        sendMessage: sendMessage,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
}
