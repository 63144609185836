import styled from "@emotion/styled";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { child, getDatabase, onValue, ref } from "firebase/database";
import * as React from "react";
import { MapRef as MapRefMapbox } from "react-map-gl";
import {
  MapRef as MapLibreRef,
  NavigationControl,
  ScaleControl,
} from "react-map-gl/maplibre";
import { Link, useParams } from "react-router-dom";
import ChatContextProvider from "./Chat";
import ChatComponent from "./ChatComponent";
import CursorsContextProvider from "./Cursors";
import { Streamer, useStreamers } from "./hooks";
import { defaultLongitude, Map } from "./Map";
import { PARTYKIT_SERVER } from "./partykit";
import { Session } from "./PastSessions";
import TwitchLogo from "./TwitchLogo";
const TWITCH_CLIENT_ID = "a6rvm91td9m00vach7ozphvbm80jkv";

const Configuration = React.lazy(() =>
  import("./Configuration").then((module) => ({
    default: module.Configuration,
  }))
);

const PastSessions = React.lazy(() =>
  import("./PastSessions").then((module) => ({
    default: module.PastSessions,
  }))
);

const Toggle = styled.div<{ isActive: boolean; showVideo: boolean }>`
  display: none;
  position: relative;
  top: 5px;
  margin-right: 16px;

  z-index: 3;

  -webkit-user-select: none;
  user-select: none;

  @media (max-width: 1181px) {
    display: ${({ showVideo }) => (showVideo ? "block" : "none")};
  }
  @media (max-width: 1000px) {
    display: ${({ showVideo }) => (showVideo ? "block" : "none")};
  }

  @media (max-width: 800px) {
    display: block;
  }

  span {
    display: block;
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;

    background: ${({ isActive }) => (isActive ? "black" : "white")};

    transform: ${({ isActive }) =>
      isActive
        ? "rotate(45deg) translate(-2px, -2px)"
        : "rotate(0) translate(0)"};

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    box-shadow: ${({ isActive }) => (isActive ? "none" : "2px 2px 0px black")};
  }

  span:nth-last-child(3) {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  }

  span:nth-last-child(1) {
    transform-origin: 0% 100%;
    transform: ${({ isActive }) =>
      isActive
        ? "rotate(-45deg) translate(0, -1px)"
        : "rotate(0) translate(0)"};
  }
`;

function HamburgerMenu({
  isActive,
  onClick,
  showVideo,
}: {
  isActive: boolean;
  showVideo: boolean;
  onClick: () => void;
}) {
  return (
    <Toggle isActive={isActive} onClick={onClick} showVideo={showVideo}>
      <span></span>
      <span></span>
      <span></span>
    </Toggle>
  );
}

const Overlay = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  background-color: ${({ visible }) =>
    visible ? "rgba(0, 0, 0, 0.3)" : "transparent"};
  transition: background-color 0.3s ease-out;
  z-index: 2;

  a {
    text-decoration: none;
    color: #f6f3ed;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px 10px; /* Adjust padding as necessary */
  }
`;

const Menu = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 100%;
  background-color: #848484;
  box-shadow: ${({ visible }) => (visible ? "0 4px 10px black" : "none")};
  transform: ${({ visible }) =>
    visible ? "translateX(0)" : "translateX(-100%)"};
  transition: all 0.3s ease-out;
  padding: 16px;
  box-sizing: border-box;
  padding-top: 48px;
  max-width: 300px;
`;
const TopLeft = styled.div<{ showVideo: boolean }>`
  display: flex;
  align-items: left;
  padding-left: 20px;

  @media (max-width: 800px) {
    padding-left: ${({ showVideo }) => (showVideo ? "25px" : "25px")};
    margin-top: ${({ showVideo }) => (showVideo ? "0px" : "-10px")};
  }
`;

const H1 = styled.h1<{ showVideo?: boolean }>`
  font-size: ${({ showVideo }) => (showVideo ? "5vw" : "62pt")};
  font-family: Hanson, Arial, sans-serif;
  color: white;
  text-shadow: 4px 4px 0px black;
  margin: 0;
  display: flex;
  align-content: flex-start;

  @media (max-width: 1600px) {
    font-size: ${({ showVideo }) => (showVideo ? "36pt" : "62pt")};
  }

  @media (max-width: 1300px) {
    font-size: ${({ showVideo }) => (showVideo ? "3vw" : "54pt")};
  }

  @media (max-width: 1181px) {
    font-size: ${({ showVideo }) => (showVideo ? "3vw" : "54pt")};
    margin-top: ${({ showVideo }) => (showVideo ? "calc(5px + 0.2vw)" : "0")};
    margin-left: ${({ showVideo }) => (showVideo ? "35px" : "0")};
    text-shadow: 2px 2px 0px black;
  }

  @media (max-width: 1000px) {
    font-size: ${({ showVideo }) => (showVideo ? "20pt" : "48pt")};
    margin-top: ${({ showVideo }) => (showVideo ? "15px" : "0")};
    margin-left: ${({ showVideo }) => (showVideo ? "35px" : "0")};
    text-shadow: 2px 2px 0px black;
  }

  @media (max-width: 800px) {
    margin-top: 20px;
    margin-left: 30px;
    font-size: 20pt;
    text-shadow: 2px 2px 0px black;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: gray;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px solid transparent;
  opacity: 0.3;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 70%;
    height: 2px;
    background-color: white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
  &:hover {
    opacity: 1;
  }
`;
const BottomRight = styled.div`
  position: absolute;
  bottom: 30px;
  right: 50px;
  padding: 8px;
  background-color: #848484;
  color: #f6f3ed;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  @media (max-width: 800px) {
    display: none;
  }
`;

const BottomLeft = styled.div`
  position: absolute;
  display: flex;
  max-width: 500px;
  bottom: 20px;
  left: 0;
  padding: 16px;
  color: white;
`;

const Top = styled.div<{ showVideo: boolean }>`
  position: absolute;
  top: ${({ showVideo }) => (showVideo ? "5px" : "10px")};
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  @media (max-width: 1600px) {
  }

  @media (max-width: 1300px) {
    top: ${({ showVideo }) => (showVideo ? "10px" : "10px")};
  }

  @media (max-width: 1000px) {
    flex-direction: ${({ showVideo }) => (showVideo ? "column" : "row")};
    top: ${({ showVideo }) => (showVideo ? "5px" : "10px")};
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    top: ${({ showVideo }) => (showVideo ? "5px" : "10px")};

    margin-top: ${({ showVideo }) => (showVideo ? "-5px" : "1px")};
  }
`;
const TopRight = styled.div<{
  videoOn?: boolean;
  login: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  height: ${({ videoOn, login }) => (videoOn && !login ? "3.5vw" : "45pt")};
  height: ${({ videoOn, login }) =>
    videoOn && login ? "fit-content" : "45pt"};

  opacity: 0.9;
  padding-right: 10px;
  padding-left: 10px;
  font-size: ${({ videoOn }) => (videoOn ? "1.5vw" : "1.7rem")};
  white-space: nowrap;
  transition: 0.3s;
  max-width: fit-content;
  min-width: fit-content;
  margin-right: 20px;
  transition: 0.3s;
  margin-top: 5px;
  background-color: ${({ videoOn, login }) =>
    videoOn && login
      ? "transparent"
      : !videoOn && !login
      ? "#848484"
      : videoOn && !login
      ? "#848484"
      : "#848484"};

  flex-direction: ${({ videoOn, login }) =>
    login && videoOn ? "column" : "row"};
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 1600px) {
    font-size: ${({ videoOn }) => (videoOn ? "1.2rem" : "1.7rem")};
    height: ${({ videoOn }) => (videoOn ? "40px" : "65px")};
    margin-left: ${({ videoOn }) => (videoOn ? "20px" : "0px")};
    margin-top: ${({ videoOn }) => (videoOn ? "0px" : "2px")};
    height: ${({ videoOn, login }) => videoOn && login && "fit-content"};
  }

  @media (max-width: 1300px) {
    font-size: ${({ videoOn }) => (videoOn ? "1.5vw" : "1.3rem")};
    height: ${({ videoOn }) => (videoOn ? "3vw" : "55px")};
    margin-left: ${({ videoOn }) => (videoOn ? "10px" : "0px")};
    margin-top: ${({ videoOn }) => (videoOn ? "-0.35vw" : "0px")};
    height: ${({ videoOn, login }) => videoOn && login && "fit-content"};
  }
  @media (max-width: 1181px) {
    display: ${({ videoOn }) => (videoOn ? "none" : "flex")};
    height: ${({ videoOn, login }) => videoOn && login && "fit-content"};
  }

  @media (max-width: 1000px) {
    display: ${({ videoOn }) => (videoOn ? "none" : "flex")};
    font-size: ${({ videoOn }) => (videoOn ? "1.2rem" : "1.3rem")};
    height: ${({ videoOn }) => (videoOn ? "30px" : "50px")};
    margin-right: ${({ videoOn }) => (videoOn ? "0px" : "20px")};
    margin-left: ${({ videoOn }) => (videoOn ? "20px" : "0px")};
    flex-direction: ${({ videoOn, login }) =>
      login && !videoOn ? "column" : "row"};
    flex-direction: ${({ videoOn, login }) =>
      login && !videoOn ? "column" : "row"};

    height: ${({ videoOn, login }) => !videoOn && login && "fit-content"};

    margin-top: ${({ videoOn }) => (videoOn ? "5px" : "5px")};
    background-color: ${({ login }) => (login ? "transparent" : "#848484")};
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const ButtonContent = styled.a<{ login: boolean }>`
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: ${({ login }) => (login ? "100%" : "fit-content")};
  padding: ${({ login }) => (login ? "10px" : "5px 10px")};
  ${({ login }) => (login ? "border-radius: 12px" : "")};
  ${({ login }) => (login ? "opacity:0.7" : "")};
  border: 1.5px solid transparent;
  background-color: ${({ login }) => (login ? "#848484" : "transparent")};
  &:hover {
    opacity: 1;
    border: ${({ login }) =>
      login ? " 1.5px solid rgba(0, 0, 0, 0.2)" : "1.5px solid transparent"};
  }
`;

const Controls = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const Layout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

type CombinedMapRef = MapRefMapbox & MapLibreRef;

function App({
  showVideo = false,
  fullScreen = false,
}: {
  showVideo: boolean;
  fullScreen?: boolean;
}) {
  const { id: activeStreamerId } = useParams();

  const streamers = useStreamers();

  const activeStreamer = React.useMemo(() => {
    return streamers.find((s) => s.streamerId === activeStreamerId);
  }, [activeStreamerId, streamers]);

  const channel = activeStreamer?.url.split("/").pop();
  return (
    <Layout>
      {!fullScreen && (
        <div style={{ flex: 1, position: "relative" }}>
          <Main
            streamers={streamers}
            activeStreamerId={activeStreamerId}
            fullScreen={fullScreen}
            showVideo={showVideo}
          />
        </div>
      )}
      {showVideo && activeStreamer && (
        <>
          <Link to={{ pathname: `/${activeStreamerId}` }}>
            <CloseButton />
          </Link>
          {fullScreen ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <iframe
                src={`https://player.twitch.tv/?channel=${channel}&parent=${window.location.hostname}`}
                frameBorder="0"
                allowFullScreen={true}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                flex: "0 0 640px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <iframe
                src={`https://player.twitch.tv/?channel=${channel}&parent=${window.location.hostname}`}
                frameBorder="0"
                allowFullScreen={true}
                style={{
                  flex: "0 0 360px",
                  width: "640px",
                  height: "360px",
                  border: "none",
                }}
              />
              <iframe
                src={`https://www.twitch.tv/embed/${channel}/chat?darkpopout&parent=${window.location.hostname}`}
                frameBorder="0"
                style={{
                  width: "100%",
                  flex: 1,
                  border: "none",
                }}
              />
            </div>
          )}
        </>
      )}
    </Layout>
  );
}

function Main({
  streamers,
  activeStreamerId,
  fullScreen,
  showVideo,
}: {
  streamers: Streamer[];
  activeStreamerId?: string;
  fullScreen?: boolean;
  showVideo: boolean;
}) {
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const [isConfigurationOpen, setConfigurationOpen] = React.useState(false);

  const [isPastSessionsOpen, setPastSessionsOpen] = React.useState(false);

  const [pastSessions, setPastSessions] = React.useState<Session[]>([]);

  const [activeStyle, setActiveStyle] = React.useState("mapbox/dark-v10");

  const [user, setUser] = React.useState(getAuth().currentUser);

  const [idToken, setIdToken] = React.useState<string | null>(null);

  const mapRef = React.useRef<CombinedMapRef | null>(null);

  React.useEffect(() => getAuth().onAuthStateChanged(setUser), []);

  React.useEffect(() => setUserId(getAnalytics(), user?.uid ?? ""), [user]);

  React.useEffect(() => {
    const getToken = async () => {
      if (user) {
        setIdToken(await user.getIdToken(true));
      }
    };

    getToken();
  }, [user]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    return onValue(child(ref(getDatabase(), "sessions"), user.uid), (data) => {
      const values: [string, Session][] = Object.entries(data.val() || {});

      const sessions = values
        .map(([k, v]) => ({
          sessionId: k,
          startedAt: new Date(v.startedAt),
          endedAt: new Date(v.endedAt),
          video: v.video,
          count: v.count,
        }))
        .filter((session) => session.count > 0)
        .sort((a, b) => Number(b.endedAt) - Number(a.endedAt));

      setPastSessions(sessions);
    });
  }, [user]);

  const redirectUri = encodeURIComponent(window.location.origin);
  const authHref = `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=${TWITCH_CLIENT_ID}&redirect_uri=${redirectUri}&scope=user%3Aread%3Aemail&force_verify=true`;
  return (
    <>
      <CursorsContextProvider token={idToken ?? ""} host={PARTYKIT_SERVER}>
        <Map
          activeStyle={activeStyle}
          streamers={streamers}
          activeStreamerId={activeStreamerId}
          fullScreen={fullScreen}
          showVideo={showVideo}
          mapRef={mapRef}
          Menu={() => (
            <HamburgerMenu
              isActive={isMenuOpen}
              showVideo={showVideo}
              onClick={() => setMenuOpen(!isMenuOpen)}
            />
          )}
          Controls={() => (
            <Controls>
              <NavigationControl
                position="bottom-right"
                style={{ color: "#F6F3ED", backgroundColor: "#848484" }}
              />
              <ScaleControl maxWidth={100} unit="metric" />
            </Controls>
          )}
          Widgets={() => (
            <>
              <BottomRight>
                <label>
                  <input
                    type="radio"
                    name="style"
                    checked={activeStyle === "mapbox/satellite-streets-v11"}
                    onChange={() => {
                      setActiveStyle("mapbox/satellite-streets-v11");
                      logEvent(getAnalytics(), "set_active_style", {
                        style: "mapbox/satellite-streets-v11",
                      });
                    }}
                  />
                  Satellite
                </label>
                <label>
                  <input
                    type="radio"
                    name="style"
                    checked={activeStyle === "mapbox/dark-v10"}
                    onChange={() => {
                      setActiveStyle("mapbox/dark-v10");
                      logEvent(getAnalytics(), "set_active_style", {
                        style: "mapbox/dark-v10",
                      });
                    }}
                  />
                  Not Satellite
                </label>
              </BottomRight>
              <Top showVideo={showVideo}>
                <TopLeft showVideo={showVideo}>
                  <H1 showVideo={showVideo}>
                    <Link
                      to="/"
                      style={{ color: "#F6F3ED", textDecoration: "none" }}
                      onClick={() => {
                        logEvent(getAnalytics(), "clear_active_streamer");
                        mapRef.current?.easeTo({
                          zoom: 1.2,
                          center: [defaultLongitude(), 0],
                        });
                      }}
                    >
                      REALTIMEIRL
                    </Link>
                  </H1>
                </TopLeft>

                {!user ? (
                  <TopRight videoOn={showVideo} login={!!user}>
                    <ButtonContent login={!!user} href={authHref}>
                      <TwitchLogo /> <span>Sign in with Twitch</span>
                    </ButtonContent>
                  </TopRight>
                ) : (
                  <TopRight videoOn={showVideo} login={!!user}>
                    <ButtonContent
                      login={!!user}
                      href="#"
                      style={{ textDecoration: "none", color: "white" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setConfigurationOpen(true);
                      }}
                    >
                      Configure
                    </ButtonContent>
                    {pastSessions.length > 0 ? (
                      <>
                        <br />
                        <ButtonContent
                          login={!!user}
                          href="#"
                          style={{ textDecoration: "none", color: "white" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setPastSessionsOpen(true);
                          }}
                        >
                          Past Routes
                        </ButtonContent>
                      </>
                    ) : null}
                    <br />
                    <ButtonContent
                      login={!!user}
                      href="/tags"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Tagged Locations
                    </ButtonContent>
                  </TopRight>
                )}
              </Top>
            </>
          )}
        />
      </CursorsContextProvider>

      <Overlay visible={isMenuOpen} onClick={() => setMenuOpen(false)}>
        <Menu visible={isMenuOpen}>
          <hr />
          {!user ? (
            <a
              href={authHref}
              style={{
                textDecoration: "none",
                color: "#F6F3ED",
                display: "flex",
                justifyContent: "center",
                padding: "2px",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <TwitchLogo /> Sign in with Twitch
            </a>
          ) : (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setConfigurationOpen(true);
              }}
            >
              Configure
            </a>
          )}
          <hr />
          <div
            style={{
              marginTop: "15px",
              color: "white",
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            Map Options
            <br />
            <label>
              <input
                type="radio"
                name="satellite_hamburger"
                checked={activeStyle === "mapbox/satellite-streets-v11"}
                onChange={() => {
                  setActiveStyle("mapbox/satellite-streets-v11");
                  logEvent(getAnalytics(), "set_active_style", {
                    style: "mapbox/satellite-streets-v11",
                  });
                }}
              />
              Satellite
            </label>
            <label>
              <input
                type="radio"
                name="satellite_hamburger"
                checked={activeStyle === "mapbox/dark-v10"}
                onChange={() => {
                  setActiveStyle("mapbox/dark-v10");
                  logEvent(getAnalytics(), "set_active_style", {
                    style: "mapbox/dark-v10",
                  });
                }}
              />
              Not Satellite
            </label>
          </div>
        </Menu>
      </Overlay>
      {user && isConfigurationOpen && (
        <React.Suspense fallback={<></>}>
          <Configuration
            user={user}
            close={() => setConfigurationOpen(false)}
          />
        </React.Suspense>
      )}
      {user && isPastSessionsOpen && (
        <React.Suspense fallback={<></>}>
          <PastSessions
            sessions={pastSessions}
            close={() => setPastSessionsOpen(false)}
          />
        </React.Suspense>
      )}
      {!showVideo && (
        <ChatContextProvider token={idToken ?? ""} host={PARTYKIT_SERVER}>
          <BottomLeft>
            <ChatComponent isAuthenticated={!!idToken} />
          </BottomLeft>
        </ChatContextProvider>
      )}
    </>
  );
}

export default App;
