import styled from "@emotion/styled";
import { useState } from "react";
import { Marker, Popup } from "react-map-gl";
import { TagMarker } from "./TaggedLocations";

const TagDescription = styled.input`
  border: none;
  width: 100%;
  height: 40px;
  background-color: #f1f1f1;
`;

const CreateButton = styled.button`
  background-color: #6441a5;
  border: none;
  border-radius: 5px;
  color: white;
  width: 50%;
  padding: 4px;
`;

const IsPublicCheckbox = styled.input`
  background-color: #6441a5;
  border: none;
`;

const TwitchClipLink = styled.input`
  margin-top: 10px;
  border: none;
  width: 100%;
  height: 20px;
`;

const ExpirationCheckbox = styled.input`
  background-color: #6441a5;
  border: none;
`;

export function TaggedLocationCreator({
  pushKey,
  lat,
  lng,
  onClose,
  onCreate,
}: {
  pushKey: string;
  lat: number;
  lng: number;
  onClose: () => void;
  onCreate: () => void;
}) {
  const [description, setDescription] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [twitchClipId, setTwitchClipLinkId] = useState("");
  const [withExpiration, setWithExpiration] = useState(false);
  const [expiresAt, setExpiresAt] = useState<Date>();

  async function submitMarker() {
    try {
      const response = await fetch(
        `https://rtirl.com/api/pins?key=${pushKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            latitude: lat,
            longitude: lng,
            description: description,
            isPublic: isPublic,
            twitchClipId: twitchClipId || null,
            withExpiration: withExpiration,
            expiresAt: withExpiration ? expiresAt?.toISOString() : null,
          }),
        }
      );
      if (response.status === 200) {
        onCreate();
      }
    } catch (err) {
      //TODO prolly do something else
      console.error(err);
    }
  }

  return (
    <div key={`new-marker`}>
      <Marker
        latitude={lat}
        longitude={lng}
        anchor="top"
        style={{
          zIndex: 1000,
        }}
      >
        <TagMarker></TagMarker>
      </Marker>
      <Popup
        latitude={lat}
        longitude={lng}
        closeButton={true}
        closeOnClick={false}
        onClose={onClose}
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "5px",
          border: "2px solid #6441a5",
          zIndex: 10,
        }}
        offset={-15}
      >
        New Tag!
        <br />
        <TagDescription
          required={true}
          type="text"
          placeholder="Add a description!"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <TwitchClipLink
          type="text"
          placeholder="Add your Twitch Clip Id!"
          onChange={(event) => setTwitchClipLinkId(event.target.value)}
        />
        <div>
          <ExpirationCheckbox
            checked={withExpiration}
            type="checkbox"
            onChange={(event) => setWithExpiration(event.target.checked)}
          />
          <label>
            {" "}
            {`turn ${withExpiration ? "off" : "on"} expiration date`}{" "}
          </label>
        </div>
        {withExpiration && (
          <input
            type="datetime-local"
            onChange={(event) => {
              setExpiresAt(new Date(event.target.value));
            }}
          ></input>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "16px",
          }}
        >
          <div>
            <IsPublicCheckbox
              checked={isPublic}
              type="checkbox"
              onClick={() => setIsPublic(!isPublic)}
            />
            <label> public </label>
          </div>
          <CreateButton onClick={submitMarker}>Create</CreateButton>
        </div>
      </Popup>
    </div>
  );
}
