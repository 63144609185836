const TwitchIcon = () => {
  return (
    <img
      src={"/TwitchGlitchPurple.svg"}
      style={{ width: "25px", marginRight: "0.5rem"}}
    />
  );
};

export default TwitchIcon;
