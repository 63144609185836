import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Editor from "./Editor";
import "./index.css";

// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

getAnalytics();

if (window.location.hash != "") {
  // parse it with URLSearchParams
  const params = new URLSearchParams(window.location.hash.substring(1));
  // get the access token
  const accessToken = params.get("access_token");
  // validate it with the server
  httpsCallable(
    getFunctions(),
    "twitchAuth"
  )(accessToken).then((result) => {
    if (typeof result.data === "string") {
      signInWithCustomToken(getAuth(), result.data);
    }
  });
  history.replaceState(null, "", window.location.href.split("#")[0]);
}

const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App showVideo={false} />} />
        <Route path="/:id" element={<App showVideo={false} />} />
        <Route path="/:id/live" element={<App showVideo={true} />} />
        <Route path="tags" element={<Editor />} />
        <Route
          path="/:id/live/fullscreen"
          element={<App showVideo={true} fullScreen={true} />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
