import styled from "@emotion/styled";
import { Logout } from "@emotion-icons/material-outlined/Logout";
import { LogoAppleAppstore } from "@emotion-icons/ionicons-solid/LogoAppleAppstore";
import { LogoGooglePlaystore } from "@emotion-icons/ionicons-solid/LogoGooglePlaystore";

const Ad = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  z-index: 100011;
  box-shadow: 0 4px 10px rgb(58 60 63 / 20%);
  border-radius: 10px;
`;

const BlurBg = styled.div`
  backdrop-filter: blur(5px);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100010;
`;

export default function TagsAd({ showAd }: { showAd: boolean }) {
  return (
    <>
      {showAd && (
        <BlurBg>
          <Ad>
            <h1>Oops</h1>
            <p>
              This is a premium feature, you can subscribe for it using our
              mobile applications!
            </p>

            <p>
              Beacon app:{" "}
              <a href="https://play.google.com/store/apps/details?id=com.rtirl.beacon">
                <LogoGooglePlaystore size={32} color="#01875f" />
              </a>{" "}
              <a href="https://apps.apple.com/app/id1558493719">
                <LogoAppleAppstore size={32} color="#0E7AFE" />
              </a>
            </p>
            <p>
              This feature allows you to share locations your viewers (different
              to your current location), you can share your destination, your
              favorite spots or any other location.
            </p>

            <p>
              Join the{" "}
              <a href="https://discord.gg/UKHJMQs74u">Muxable discord </a> for
              updates, feature requests, and community support
            </p>
            <br />
            <hr />
            <div>
              <a
                href="/"
              >
                <Logout size={24} color="red" />
              </a>
            </div>
          </Ad>
        </BlurBg>
      )}
    </>
  );
}
