import styled from "@emotion/styled";
import { TaggedLocation } from "./hooks";

const Tile = styled.div`
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-decoration: none;
  &:hover,
  &:focus {
    background-color: #f1f1f1;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: 12px;
`;

export default function TagListItem({ tag }: { tag: TaggedLocation }) {
  return (
    <Tile key={tag.id}>
      <Title>{tag.description}</Title>
      <Subtitle>Latitude: {tag.latitude}</Subtitle>
      <Subtitle>Longitude: {tag.longitude}</Subtitle>
      <Subtitle>Created: {tag.createdAt.toLocaleString()}</Subtitle>
    </Tile>
  );
}
