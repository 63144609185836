import { initializeApp } from "firebase/app";

export const DEFAULT_APP = initializeApp({
  apiKey: "AIzaSyC4L8ICZbJDufxe8bimRdB5cAulPCaYVQQ",
  authDomain: "rtirl-a1d7f.firebaseapp.com",
  databaseURL: "https://rtirl-a1d7f-default-rtdb.firebaseio.com",
  projectId: "rtirl-a1d7f",
  storageBucket: "rtirl-a1d7f.appspot.com",
  messagingSenderId: "684852107701",
  appId: "1:684852107701:web:22f87dcafccc98249a61fc",
  measurementId: "G-F1NVN4L0B7",
});

export const LOCATIONS_APP = initializeApp(
  {
    apiKey: "AIzaSyC4L8ICZbJDufxe8bimRdB5cAulPCaYVQQ",
    authDomain: "rtirl-a1d7f.firebaseapp.com",
    databaseURL: "https://rtirl-a1d7f-locations-rtdb.firebaseio.com",
    projectId: "rtirl-a1d7f",
    storageBucket: "rtirl-a1d7f.appspot.com",
    messagingSenderId: "684852107701",
    appId: "1:684852107701:web:22f87dcafccc98249a61fc",
    measurementId: "G-F1NVN4L0B7",
  },
  "locations"
);
