import {
  endAt,
  getDatabase,
  limitToLast,
  onChildAdded,
  onValue,
  orderByChild,
  orderByKey,
  query,
  ref,
  startAt,
} from "firebase/database";
import {
  collection,
  getFirestore,
  onSnapshot,
  query as queryFirestore,
  where,
} from "firebase/firestore";
import * as React from "react";
import { LOCATIONS_APP } from "./firebase";

export type Location = {
  latitude: number;
  longitude: number;
  reportedAt: number;
};

export type TaggedLocation = {
  id: string;
  latitude: number;
  longitude: number;
  description: string;
  twitchClipId?: string;
  createdAt: Date;
  expiresAt?: Date;
};

export type Streamer = {
  streamerId: string;
  displayName: string;
  url: string;
  platform: string;
  location: Location;
  activeSessionId: string;
};

export function useStreamers() {
  const [streamers, setStreamers] = React.useState<Streamer[]>([]);

  React.useEffect(() => {
    // This query filters out items without activeSessionId
    return onValue(
      query(
        ref(getDatabase(), "streamers"),
        orderByChild("activeSessionId"),
        startAt("\u0000"),
        endAt("\uFFFF")
      ),
      (data) => {
        const subtree: { [key: string]: Streamer } = data.val() || {};
        const streamers = Object.entries(subtree)
          .filter(([, doc]) => doc.location)
          .map(([streamerId, doc]) => {
            return { ...doc, streamerId };
          });
        setStreamers(streamers);
      }
    );
  }, []);

  return streamers;
}

export function useStreamerSession(sessionId: string) {
  const [trail, setTrail] = React.useState<Location[]>([]);

  React.useEffect(() => {
    setTrail([]);

    return onChildAdded(
      query(
        ref(getDatabase(LOCATIONS_APP), sessionId),
        orderByKey(),
        limitToLast(10000)
      ),
      (data) => {
        if (data.exists()) {
          const [latitude, longitude] = data.val();
          const value = { latitude, longitude, reportedAt: Number(data.key) };
          setTrail((trail) => {
            trail.push(value);
            trail.sort((a, b) => a.reportedAt - b.reportedAt);
            return trail;
          });
        }
      }
    );
  }, [sessionId]);

  return trail;
}

export function useStreamerTags(streamerId: string) {
  const [tags, setTags] = React.useState<TaggedLocation[]>([]);

  React.useEffect(() => {
    if (!streamerId || streamerId.length === 0) {
      return;
    }
    setTags([]);
    const query = queryFirestore(
      collection(getFirestore(), "tagged-locations"),
      where("userId", "==", streamerId)
    );
    return onSnapshot(query, (querySnapshot) => {
      const newTags: TaggedLocation[] = [];
      querySnapshot.forEach((doc) => {
        const {
          latitude,
          longitude,
          description,
          createdAt,
          twitchClipId,
          expiresAt,
        } = doc.data();
        const newTag = {
          id: doc.id,
          latitude,
          longitude,
          description,
          twitchClipId,
          expiresAt: expiresAt ? expiresAt.toDate() : null,
          createdAt: createdAt.toDate(),
        } as TaggedLocation;
        newTags.push(newTag);
        newTags.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
      });
      setTags([...newTags]);
    });
  }, [streamerId]);

  return tags;
}
