import React from "react";
import { Layer, Source } from "react-map-gl";
import { useStreamerSession } from "./hooks";

export function Trail({
  sessionId,
  onLocation,
}: {
  sessionId: string;
  onLocation: (location: { latitude: number; longitude: number }) => void;
}) {
  const locations = useStreamerSession(sessionId);

  React.useEffect(() => {
    if (locations.length > 0) {
      onLocation(locations[locations.length - 1]);
    }
  }, [locations.length]);

  return (
    <Source
      type="geojson"
      data={{
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: locations.map((location) => {
            return [location.longitude, location.latitude];
          }),
        },
      }}
    >
      <Layer
        id="session"
        type="line"
        paint={{
          "line-color": "#DFFB26",
          "line-width": 2,
        }}
      />
    </Source>
  );
}
