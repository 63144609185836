import { FormEvent, useRef } from "react";
import { Message, useMessages } from "./Chat";

export default function ChatComponent({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const { messages, sendMessage } = useMessages();

  function scrollToBottom() {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const text = event.currentTarget.message.value;
    if (text?.trim()) {
      sendMessage(text);
      event.currentTarget.message.value = "";
      scrollToBottom();
    }
  }

  return (
    <div>
      <div
        style={{
          overflowY: "scroll",
          maxHeight: "30vh",
          maxWidth: "30vw",
          scrollbarWidth: "none",
        }}
      >
        {messages.map((message) => (
          <MessageComponent key={message.id} message={message} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      {isAuthenticated && (
        <form onSubmit={submit}>
          <input
            placeholder="Send message..."
            type="text"
            name="message"
            style={{
              borderRadius: "5px",
              borderWidth: "1px",
              borderColor: "white",
              color: "white",
              background: "transparent",
            }}
          ></input>
        </form>
      )}
    </div>
  );
}

function MessageComponent(props: { message: Message }) {
  const { message } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: "5px",
      }}
    >
      <div
        style={{
          color: message.color,
        }}
      >
        {`${message.from}:`}{" "}
      </div>
      <div>{message.text}</div>
    </div>
  );
}
