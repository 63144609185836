import styled from "@emotion/styled";
import { useState } from "react";
import { MAPBOX_ACCESS_TOKEN } from "./mapbox";

export type GeocodingResult = {
  title: string;
  subtitle: string;
  latitude: number;
  longitude: number;
};

const Input = styled.input`
  width: 90%;
  background-color: #f1f1f1;
  border: none;
  padding: 5px;
  margin: 0 auto 10px auto;
  border-radius: 5px;
`;

const ResultPanel = styled.div`
  position: absolute;
  left: 13px;
  width: 90%;
  background-color: white;
  top: 80px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgb(58 60 63 / 20%);
`;

const ResultItem = styled.div`
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-decoration: none;
  &:hover,
  &:focus {
    background-color: #f1f1f1;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: 12px;
`;

export default function SearchBar({
  disabled,
  onResultClick,
}: {
  disabled: boolean;
  onResultClick: (lat: number, long: number) => void;
}) {
  const [searchText, setSearchText] = useState("");
  const [points, setPoints] = useState<GeocodingResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // TODO do something with errors :)
  const [error, setError] = useState(null);

  function forwardGeocode(keywords: string) {
    setIsLoading(true);
    const encodedKeywords = encodeURIComponent(keywords);
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedKeywords}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          const points: GeocodingResult[] = result.features.map(
            (feature: any) => {
              return {
                title: feature.text,
                subtitle: feature.place_name,
                latitude: feature.center[1],
                longitude: feature.center[0],
              };
            }
          );
          setIsLoading(false);
          setPoints(points);
        },
        (error) => {
          setIsLoading(false);
          setError(error);
        }
      );
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    // Clear points for a new search
    setPoints([]);

    // Update value
    setSearchText(event.currentTarget.value);

    // It's probably ok to only geocode at this point?
    if (event.currentTarget.value.length > 3) {
      forwardGeocode(searchText);
    }
  }

  return (
    <>
      <Input
        type="text"
        placeholder="Search for a place to tag..."
        onChange={handleChange}
        disabled={disabled}
      />
      {isLoading && (
        <ResultPanel>
          <ResultItem>Loading...</ResultItem>;
        </ResultPanel>
      )}
      {!isLoading && points.length > 0 && (
        <ResultPanel>
          {points.map((point, index) => {
            return (
              <ResultItem
                key={`${point.title}-${index}`}
                onClick={() => onResultClick(point.latitude, point.longitude)}
              >
                <Title>{point.title}</Title>
                <Subtitle>{point.subtitle}</Subtitle>
              </ResultItem>
            );
          })}
        </ResultPanel>
      )}
    </>
  );
}
