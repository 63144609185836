import styled from "@emotion/styled";
import React from "react";
import { Popup } from "react-map-gl";
import { TaggedLocation } from "./hooks";

const DeleteButton = styled.div`
  margin-top: 20px;
  background-color: #ff4444;
  border: none;
  border-radius: 5px;
  color: white;
  text-align: center;
`;

export default React.memo(
  function TagPopup({
    tag,
    setShowPopup,
    onDelete,
  }: {
    tag: TaggedLocation;
    setShowPopup: () => void;
    onDelete?: (tagId: string) => void;
  }) {
    return (
      <Popup
        latitude={tag.latitude}
        longitude={tag.longitude}
        closeButton={true}
        closeOnClick={false}
        onClose={() => setShowPopup()}
        style={{
          position: "absolute",
          backgroundColor: "#FFFFFF",
          borderRadius: "5px",
          border: "2px solid #6441a5",
          zIndex: 3,
          minWidth: tag.twitchClipId ? "548px" : "256px",
        }}
      >
        {tag.twitchClipId && (
          <iframe
            src={`https://clips.twitch.tv/embed?clip=${tag.twitchClipId}&parent=rtirl.com`}
            height="256"
            width="512"
            style={{
              border: "none",
              borderRadius: "5px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          ></iframe>
        )}
        <div>{tag.description}</div>
        {onDelete ? (
          <>
            <br />
            <DeleteButton
              onClick={() => {
                setShowPopup();
                onDelete(tag.id);
              }}
            >
              Delete
            </DeleteButton>
          </>
        ) : (
          <div
            style={{
              position: "absolute",
              bottom: "4px",
              right: "16px",
            }}
          ></div>
        )}
      </Popup>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.tag.id === nextProps.tag.id;
  }
);
