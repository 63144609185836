import styled from "@emotion/styled";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  collection,
  getFirestore,
  onSnapshot,
  query as queryFirestore,
  where,
} from "firebase/firestore";
import "maplibre-gl/dist/maplibre-gl.css";
import React from "react";
import ReactMapboxGL, {
  AttributionControl,
  MapLayerMouseEvent,
  MapRef as MapRefMapbox,
  ViewStateChangeEvent,
} from "react-map-gl";
import ReactMapGL, {
  Layer,
  MapLayerMouseEvent as MLMEventMapLibre,
  MapRef as MapLibreRef,
  Marker,
  Source,
  ViewStateChangeEvent as VSCEventMapLibre,
} from "react-map-gl/maplibre";
import { Link } from "react-router-dom";
import flagImage from "../src/assets/location-pin.png";
import { useCursors } from "./Cursors";
import { Streamer, TaggedLocation } from "./hooks";
import { MAPBOX_ACCESS_TOKEN } from "./mapbox";
import { osmStyle } from "./maplibre";
import { TaggedLocations } from "./TaggedLocations";
import TagPopup from "./TagPopup";
import { Trail } from "./Trail";

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const TopLeft = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  padding: 16px;
`;

const MarkerLabel = styled.strong`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-shadow: 1.5px 1.5px 0px #6441a5;
  font-size: 14pt;
  white-space: nowrap;
`;

const layerStyle: any = {
  id: "publicTags",
  type: "symbol",
  minzoom: 4,
  layout: {
    "icon-image": "location-pin",
    "icon-size": 0.1,
  },
};

type CombinedMapRef = MapRefMapbox & MapLibreRef;

type MapProps = React.PropsWithChildren<{
  streamers: Streamer[];
  activeStreamerId?: string;
  activeStreamerLocation?: { latitude: number; longitude: number };
  activeStyle: string;
  showVideo: boolean;
  mapRef: React.MutableRefObject<CombinedMapRef | null>;
  sendPositionCallback: (lat?: number, long?: number) => void;
}>;

type MapState = {
  dirty: boolean;
  viewport: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  publicTagsGeoJson: any;
  selectedPublicTag: TaggedLocation | null;
  showPopup: boolean; // for the publicTag popup
};

export function defaultLongitude() {
  const now = new Date();
  const h = now.getUTCHours();
  const m = now.getUTCMinutes();
  const s = now.getUTCSeconds();
  return 180 - (360 * ((h * 60 + m) * 60 + s)) / 86400;
}

class ManagedMap extends React.Component<MapProps, MapState> {
  constructor(props: MapProps) {
    super(props);

    this.state = {
      dirty: false,
      viewport: {
        latitude: 0,
        longitude: defaultLongitude(),
        zoom: props.activeStreamerId ? 15 : 1.2,
      },
      publicTagsGeoJson: {
        type: "FeatureCollection",
        id: "publicTags",
        features: [],
      },
      selectedPublicTag: null,
      showPopup: false,
    };
  }

  componentDidMount(): void {
    const query: any = queryFirestore(
      collection(getFirestore(), "tagged-locations"),
      where("isPublic", "==", true)
    );
    onSnapshot(query, (querySnapshot: any) => {
      let publicTags: TaggedLocation[] = [];
      querySnapshot.forEach((doc: any) => {
        const {
          latitude,
          longitude,
          description,
          createdAt,
          twitchClipId,
          expiresAt,
        } = doc.data();
        const tag = {
          id: doc.id,
          latitude,
          longitude,
          description,
          twitchClipId: twitchClipId || null,
          createdAt: createdAt.toDate(),
          expiresAt: expiresAt ? expiresAt.toDate() : null,
        };
        publicTags.push(tag);
      });

      // filtered those expired
      publicTags = publicTags.filter((tag) => {
        if (tag.expiresAt && tag.expiresAt < new Date()) {
          return false;
        }
        return true;
      });

      const publicTagsGeoJson: any = {
        type: "FeatureCollection",
        id: "publicTags",
        features: publicTags.map((tag: TaggedLocation) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [tag.longitude, tag.latitude],
            },
            properties: {
              id: tag.id,
              description: tag.description,
              twitchClipId: tag.twitchClipId,
              createdAt: tag.createdAt,
              expiresAt: tag.expiresAt,
            },
          };
        }),
      };
      this.setState({
        publicTagsGeoJson: publicTagsGeoJson,
      });
    });
  }

  componentDidUpdate(prevProps: MapProps) {
    if (
      !this.state.dirty &&
      this.props.activeStreamerLocation &&
      this.props.activeStreamerLocation !== prevProps.activeStreamerLocation
    ) {
      this.setState({
        viewport: {
          latitude: this.props.activeStreamerLocation.latitude,
          longitude: this.props.activeStreamerLocation.longitude,
          zoom: this.state.viewport.zoom,
        },
      });
    }
  }

  setDirty(dirty: boolean) {
    this.setState({ dirty });
  }

  onMove(event: ViewStateChangeEvent | VSCEventMapLibre) {
    this.setState({ viewport: event.viewState });
  }

  onClick(event: MapLayerMouseEvent | MLMEventMapLibre) {
    const features = event.features;
    if (features?.length != 1) {
      return;
    }
    const feature: any = features[0];
    const lng: number = feature.geometry.coordinates[0];
    const lat: number = feature.geometry.coordinates[1];
    this.setState({
      selectedPublicTag: {
        id: feature.properties!.id,
        latitude: lat,
        longitude: lng,
        description: feature.properties!.description,
        twitchClipId:
          feature.properties!.twitchClipId === "null"
            ? null
            : feature.properties!.twitchClipId,
        createdAt: feature.properties!.createdAt,
        expiresAt:
          feature.properties!.expiresAt === "null"
            ? null
            : feature.properties!.expiresAt,
      },
      showPopup: !this.state.showPopup,
    });
  }

  renderCommonChildren() {
    return (
      <>
        {this.props.children}
        {this.state.selectedPublicTag !== null && this.state.showPopup && (
          <TagPopup
            tag={this.state.selectedPublicTag}
            setShowPopup={() => this.setState({ showPopup: false })}
          />
        )}
        <Source
          id="publicTags"
          type="geojson"
          data={this.state.publicTagsGeoJson}
        >
          <Layer interactive={true} {...layerStyle} />
        </Source>
      </>
    );
  }

  renderMapbox() {
    return (
      <ReactMapboxGL
        ref={this.props.mapRef}
        reuseMaps //Might help reduce billable events
        {...this.state.viewport}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={`mapbox://styles/${this.props.activeStyle}`}
        onTouchStart={() => this.setDirty(true)}
        onMouseDown={() => this.setDirty(true)}
        onMove={(e) => this.onMove(e)}
        onClick={(e) => this.onClick(e)}
        onMouseMove={(e) =>
          this.props.sendPositionCallback(e.lngLat.lat, e.lngLat.lng)
        }
        onLoad={(event) => {
          const map = event.target;
          map.loadImage(flagImage, (error, image: any) => {
            if (error) throw error;
            map.addImage("location-pin", image);
          });
        }}
        interactiveLayerIds={["publicTags", "cursors-layer"]}
      >
        {this.renderCommonChildren()}
      </ReactMapboxGL>
    );
  }

  renderMapLibre() {
    return (
      <ReactMapGL
        ref={this.props.mapRef}
        {...this.state.viewport}
        attributionControl={false}
        mapStyle={osmStyle}
        onTouchStart={() => this.setDirty(true)}
        onMouseDown={() => this.setDirty(true)}
        onMouseMove={(e) =>
          this.props.sendPositionCallback(e.lngLat.lat, e.lngLat.lng)
        }
        onMouseOut={() => this.props.sendPositionCallback()} //TODO we can remove the cursor
        onMove={(e) => this.onMove(e)}
        onClick={(e) => this.onClick(e)}
        onLoad={(event) => {
          const map = event.target;
          map
            .loadImage(flagImage)
            .then((image) => map.addImage("location-pin", image.data));
        }}
        interactiveLayerIds={["publicTags", "cursors-layer"]}
      >
        {this.renderCommonChildren()}
        <AttributionControl customAttribution="MapLibre" />
      </ReactMapGL>
    );
  }

  render(): React.ReactNode {
    return this.props.activeStyle === "mapbox/satellite-streets-v11"
      ? this.renderMapbox()
      : this.renderMapLibre();
  }
}

function StreamerMarker({
  streamer,
  isActive,
  onClick,
}: {
  streamer: Streamer;
  isActive: boolean;
  onClick?: () => void;
}) {
  const [provider, channelId] = streamer.streamerId.split(":");
  return (
    <Link
      to={{ pathname: `/${streamer.streamerId}` }}
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          border: "2px solid white",
          borderRadius: "50%",
          overflow: "hidden",
          width: 32,
          height: 32,
          backgroundColor: "white",
          boxShadow: isActive ? "0px 0px 5px 1px #DFFB26" : "none",
        }}
      >
        <img
          src={`/pfp.png?provider=${provider}&channelId=${channelId}`}
          style={{ height: 32, width: 32, borderRadius: "50%" }}
        />
      </div>
      <MarkerLabel>{streamer.displayName}</MarkerLabel>
    </Link>
  );
}

export function Map({
  Menu,
  Controls,
  Widgets,
  activeStyle,
  streamers,
  activeStreamerId,
  fullScreen,
  showVideo,
  mapRef,
}: {
  Menu: () => JSX.Element;
  Controls: () => JSX.Element;
  Widgets: () => JSX.Element;
  activeStyle: string;
  streamers: Streamer[];
  activeStreamerId?: string;
  fullScreen?: boolean;
  showVideo: boolean;
  mapRef: React.MutableRefObject<CombinedMapRef | null>;
}) {
  const activeStreamer = React.useMemo(() => {
    return streamers.find((s) => s.streamerId === activeStreamerId);
  }, [streamers, activeStreamerId]);

  const [activeStreamerLocation, setActiveStreamerLocation] = React.useState<{
    latitude: number;
    longitude: number;
  }>();

  const { others, sendPosition } = useCursors();

  const cursors = React.useMemo(
    () =>
      Object.keys(others).map((key) => {
        return (
          <Marker
            key={key}
            latitude={others[key].lat}
            longitude={others[key].long}
          >
            <svg
              style={{
                position: "absolute",
                left: 0,
                top: 0,
              }}
              width="24"
              height="36"
              viewBox="0 0 24 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
                fill={others[key].color}
              />
            </svg>
          </Marker>
        );
      }),
    [others]
  );

  if (!fullScreen) {
    if (activeStreamer) {
      return (
        <Content>
          <ManagedMap
            mapRef={mapRef}
            streamers={streamers}
            activeStreamerLocation={activeStreamerLocation}
            activeStreamerId={activeStreamerId}
            activeStyle={activeStyle}
            showVideo={showVideo}
            sendPositionCallback={sendPosition}
          >
            <Trail
              sessionId={activeStreamer.activeSessionId}
              onLocation={setActiveStreamerLocation}
            />
            <TaggedLocations streamerId={activeStreamer.streamerId} />
            {streamers.map((streamer) => {
              const isActive = streamer.streamerId === activeStreamerId;
              return (
                <Marker
                  key={streamer.streamerId}
                  latitude={
                    isActive && activeStreamerLocation
                      ? activeStreamerLocation.latitude
                      : streamer.location.latitude
                  }
                  longitude={
                    isActive && activeStreamerLocation
                      ? activeStreamerLocation.longitude
                      : streamer.location.longitude
                  }
                >
                  <StreamerMarker
                    streamer={streamer}
                    onClick={() => {
                      setActiveStreamerLocation(streamer.location);
                      mapRef.current?.flyTo({
                        center: [
                          streamer.location.longitude,
                          streamer.location.latitude,
                        ],
                      });
                      logEvent(getAnalytics(), "set_active_streamer", {
                        streamerId: streamer.streamerId,
                      });
                    }}
                    isActive={streamer.streamerId === activeStreamerId}
                  />
                </Marker>
              );
            })}
            {cursors}
            <Controls />
          </ManagedMap>
          <TopLeft>
            <Menu />
          </TopLeft>
          <Widgets />
          {window.matchMedia("(min-width: 800px)") ? (
            <Link
              to={
                showVideo
                  ? { pathname: `/${activeStreamerId}` }
                  : { pathname: `/${activeStreamerId}/live` }
              }
              style={{
                position: "absolute",
                bottom: "10%",
                left: "50%",
                padding: "8px 16px",
                transform: "translateX(-50%)",
                backgroundColor: "#848484",
                color: "#F6F3ED",
                borderRadius: "4px",
                boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)",
                textDecoration: "none",
                textAlign: "center",
                ...(showVideo && window.matchMedia("(max-width: 800px)").matches
                  ? { display: "none" }
                  : {}),
              }}
              onClick={() => {
                logEvent(getAnalytics(), "click_button_twitch_link", {
                  streamerId: activeStreamer.streamerId,
                });
              }}
            >
              {showVideo
                ? "Stop watching " + activeStreamer.displayName
                : "Watch " + activeStreamer.displayName}
            </Link>
          ) : (
            <a
              href={activeStreamer.url}
              target="_blank"
              style={{
                position: "absolute",
                bottom: "40px",
                left: "50%",
                padding: "8px 16px",
                transform: "translateX(-50%)",
                backgroundColor: "#848484",
                color: "#F6F3ED",
                borderRadius: "4px",
                boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)",
                textDecoration: "none",
                textAlign: "center",
              }}
              onClick={() => {
                logEvent(getAnalytics(), "click_button_twitch_link", {
                  streamerId: activeStreamer.streamerId,
                });
              }}
            >
              Watch {activeStreamer.displayName} on Twitch
            </a>
          )}
        </Content>
      );
    }
    return (
      <Content>
        <ManagedMap
          mapRef={mapRef}
          streamers={streamers}
          activeStreamerId={activeStreamerId}
          activeStyle={activeStyle}
          showVideo={showVideo}
          sendPositionCallback={sendPosition}
        >
          {streamers.map((streamer) => {
            return (
              <Marker
                key={streamer.streamerId}
                latitude={streamer.location.latitude}
                longitude={streamer.location.longitude}
              >
                <StreamerMarker
                  streamer={streamer}
                  onClick={() => {
                    setActiveStreamerLocation(streamer.location);
                    mapRef.current?.flyTo({
                      center: [
                        streamer.location.longitude,
                        streamer.location.latitude,
                      ],
                    });
                    logEvent(getAnalytics(), "set_active_streamer", {
                      streamerId: streamer.streamerId,
                    });
                  }}
                  isActive={streamer.streamerId === activeStreamerId}
                />
              </Marker>
            );
          })}
          {cursors}
          <Controls />
        </ManagedMap>
        <TopLeft>
          <Menu />
        </TopLeft>
        <Widgets />
        {activeStreamerId && (
          <div
            style={{
              position: "absolute",
              bottom: "40px",
              left: "50%",
              padding: "8px 16px",
              transform: "translateX(-50%)",
              backgroundColor: "#848484",
              color: "#F6F3ED",
              borderRadius: "4px",
              boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)",
              textDecoration: "none",
              fontFamily: "Arial, sans-serif",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            This streamer doesn't seem to be online right now.
          </div>
        )}
      </Content>
    );
  }
  return (
    <Content>
      <ManagedMap
        mapRef={mapRef}
        streamers={streamers}
        activeStreamerId={activeStreamerId}
        activeStyle={activeStyle}
        showVideo={showVideo}
        sendPositionCallback={sendPosition}
      >
        {streamers.map((streamer) => {
          return (
            <Marker
              key={streamer.streamerId}
              latitude={streamer.location.latitude}
              longitude={streamer.location.longitude}
            >
              <StreamerMarker
                streamer={streamer}
                onClick={() => {
                  setActiveStreamerLocation(streamer.location);
                  mapRef.current?.flyTo({
                    center: [
                      streamer.location.longitude,
                      streamer.location.latitude,
                    ],
                  });
                  logEvent(getAnalytics(), "set_active_streamer", {
                    streamerId: streamer.streamerId,
                  });
                }}
                isActive={streamer.streamerId === activeStreamerId}
              />
            </Marker>
          );
        })}
        <Controls />
      </ManagedMap>
    </Content>
  );
}
