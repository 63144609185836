import styled from "@emotion/styled";
import { getAuth, User } from "firebase/auth";
import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import ReactMapLibreGL, {
  AttributionControl,
  MapRef,
} from "react-map-gl/maplibre";
import { useStreamerTags } from "./hooks";
import { osmStyle } from "./maplibre";
import SearchBar from "./SearchBar";
import { TaggedLocationCreator } from "./TagCreator";
import { TaggedLocationMarker } from "./TaggedLocations";
import TagListItem from "./TagListItem";
import TagsAd from "./TagsAd";

// TODO This is not responsive at all
const Content = styled.div`
  margin-left: 17%;
  height: 100%;
  width: 83%;
`;

const Header = styled.div`
  font-size: 20px;
  text-align: center;
  padding: 10px;
`;

const Sidebar = styled.div`
  margin: 0;
  padding: 0;
  width: 17%;
  position: fixed;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

type CreatorLocation = {
  lat: number;
  lng: number;
};

type Subscription = {
  id: string;
  expirationDate: Date;
};

export default function Editor() {
  const mapRef = useRef<MapRef>(null);
  const [showAd, setShowAd] = useState(true);
  const [pushKey, setPushKey] = useState("");
  const [userId, setUserId] = useState("");
  const tags = useStreamerTags(userId);
  const [displayCreator, setDisplayCreator] = useState(false);
  const [creatorLocation, setCreatorLocation] = useState<CreatorLocation>({
    lat: 40.74365193069188,
    lng: -73.99498258414697,
  });

  useEffect(() => {
    getAuth().onAuthStateChanged(authCallback);
  }, []);

  // Not very nice
  async function authCallback(user: User | null) {
    if (!user) {
      return;
    }

    // Get push key, ideally we'd use the userId for everything here but this might work for a bit
    onSnapshot(doc(collection(getFirestore(), "config"), user.uid), (doc) => {
      const pk = doc.get("pushKey");
      setPushKey(pk);
      checkSubscriptionStatus(pk);
    });

    setUserId(user.uid);
  }

  function displayTagCreator(lat: number, lng: number) {
    mapRef.current?.panTo({ lat, lng }, { animate: true });
    setCreatorLocation({ lat, lng });
    setDisplayCreator(true);
  }

  async function checkSubscriptionStatus(pushKey: string) {
    try {
      const response = await fetch(
        `https://rtirl.com/api/purchases/subscriptions?key=${pushKey}`
      );
      if (response.status === 200) {
        const payload = (await response.json()).subscriptions;

        const subs: Subscription[] = payload.map((sub: any) => {
          return {
            id: sub.id,
            expirationDate: new Date(sub.expiryFromEpoch),
          };
        });
        const isSubbed = subs.some(
          (sub) =>
            sub.id === "rtirl.location_tagging" &&
            sub.expirationDate > new Date()
        );
        setShowAd(!isSubbed);
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function deleteTag(tagId: string) {
    try {
      const response = await fetch(
        `https://rtirl.com/api/pins/${tagId}?key=${pushKey}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) {
        console.error("Got error status when removing tag!");
      }
    } catch (err) {
      //TODO prolly do something else
      console.error(err);
    }
  }

  const onLoad = () => {
    const map = mapRef.current?.getMap();
    if (!map) return;
  };

  return (
    <>
      <TagsAd showAd={showAd} />
      <Sidebar>
        <Header>Tagged Locations</Header>
        <SearchBar onResultClick={displayTagCreator} disabled={showAd} />
        {tags.map((tag) => {
          return <TagListItem key={tag.id} tag={tag} />;
        })}
      </Sidebar>
      <Content>
        <ReactMapLibreGL
          ref={mapRef}
          onLoad={onLoad}
          mapStyle={osmStyle}
          attributionControl={false}
          initialViewState={{
            latitude: creatorLocation.lat,
            longitude: creatorLocation.lng,
            zoom: 11,
          }}
        >
          {displayCreator && (
            <TaggedLocationCreator
              pushKey={pushKey}
              lat={creatorLocation.lat}
              lng={creatorLocation.lng}
              onClose={() => setDisplayCreator(false)}
              onCreate={() => setDisplayCreator(false)}
            />
          )}
          {tags.map((tag, index) => {
            return (
              <TaggedLocationMarker
                key={tag.id}
                tag={tag}
                index={index}
                onDelete={deleteTag}
              />
            );
          })}
          <AttributionControl customAttribution="MapLibre" />
        </ReactMapLibreGL>
      </Content>
    </>
  );
}
