import styled from "@emotion/styled";
import React from "react";
import { Marker } from "react-map-gl";
import { TaggedLocation, useStreamerTags } from "./hooks";
import TagPopup from "./TagPopup";

export const TagMarker = styled.div`
  background-color: #dffb26;
  border: 2px solid #6441a5;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
`;

export function TaggedLocations({ streamerId }: { streamerId: string }) {
  const tags = useStreamerTags(streamerId);

  return (
    <>
      {tags.map((tag, index) => {
        return <TaggedLocationMarker tag={tag} index={index} />;
      })}
    </>
  );
}

export function TaggedLocationMarker({
  tag,
  index,
  onDelete,
}: {
  tag: TaggedLocation;
  index: number;
  onDelete?: (tagId: string) => void;
}) {
  const [showPopup, setShowPopup] = React.useState(false);
  return (
    <div key={`marker-${tag.description}-${index}`}>
      <Marker
        latitude={tag.latitude}
        longitude={tag.longitude}
        anchor="top"
        style={{
          zIndex: 2,
        }}
        onClick={() => setShowPopup(!showPopup)}
      >
        <TagMarker>{index + 1}</TagMarker>
      </Marker>
      {showPopup ? (
        <TagPopup
          tag={tag}
          setShowPopup={() => setShowPopup(false)}
          onDelete={onDelete}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
